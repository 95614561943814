import React from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'next/router';
import Layout from '~/components/Layout/Layout';
import MetaTags from '~/components/MetaTags/MetaTags';
import LeagueRiverComponent from '~/components/LeagueRiver/LeagueRiver';
import NotFound from '~/components/NotFound/NotFound';
import ContentCardComponent from '~/components/ContentCard/ContentCard';
import MobileBannerAdComponent from '~/components/MobileBannerAd/MobileBannerAd';

import logEvent from '~/util/analytics';

import { initStore } from '~/state/store';
import { operations as pageDataOperations } from '~/state/pageData';
import { getTicker } from '~/state/ScoreTicker/ScoreTicker';
import withState from '~/hocs/withState';

import { bindActionCreators } from 'redux';

class LeagueRiver extends React.Component {
  static async getInitialProps(props) {
    const leagueSlug = props.query.leagueSlug || 'top_news';
    const league = props.store.getState().pageData.currentLeague;
    const countryCode = props.store.getState().location.countryCode;
    let riverDataFailure = false;
    props.store.dispatch(pageDataOperations.setAdConfig());

    if (!league.slug) {
      // eslint-disable-next-line
      props.res.statusCode = 404;
      return {};
    }

    if (props.isServer && props.asPath !== '/') {
      if (league.has_news && !props.query.section) props.res.redirect(301, `/${league.slug}/news`);
      if (!league.has_news && props.query.section) props.res.redirect(301, `/${league.slug}`);
    }
    const getRiverData = props.store.dispatch(
      pageDataOperations.loadLeagueRiver({
        league,
        currentCardId: null,
        before: null,
        riverData: { relatedContent: [], newBefore: null },
        countryCode,
      })
    );
    const riverDataResult = await getRiverData;

    if (riverDataResult.result === 'FAILURE') {
      if (props.res) {
        // eslint-disable-next-line
        props.res.statusCode = riverDataResult.statusCode;
      }
      riverDataFailure = true;
    }

    if (!props.isServer) props.store.dispatch(getTicker(leagueSlug));

    // Anything returned here will end up in props
    return {
      leagueSlug,
      isServer: props.isServer,
      leagues: props.store.getState().menuData.data.all,
      countryCode,
      league,
      riverDataFailure,
    };
  }

  componentDidMount() {
    if (this.props.isServer) this.props.getTicker(this.props.leagueSlug);
    logEvent('page_view', {
      league: this.props.league.slug,
      page_type: 'league_river',
      page_name: this.props.league.slug,
    });
  }

  componentDidUpdate(oldProps) {
    if (this.props.router.asPath !== oldProps.router.asPath)
      logEvent('page_view', {
        league: this.props.league.slug,
        page_type: 'league_river',
        page_name: this.props.league.slug,
      });
  }

  render() {
    const { riverData, league, countryCode, riverDataFailure } = this.props;
    const { relatedContent, ...newRiverData } = riverData;
    const relatedContentList = relatedContent;

    const showTag =
      !this.props.router.query.leagueSlug ||
      this.props.router.query.leagueSlug === 'top_news' ||
      this.props.router.query.leagueSlug === 'trending';

    const hasContent = !!relatedContentList.length;

    const isHomePage =
      this.props.router.asPath === '/' && Object.keys(this.props.router.query).length === 0;

    if (!hasContent || riverDataFailure) {
      return <NotFound leagueSlug={league.slug} />;
    }

    const contentCard = relatedContentList[0];
    return (
      <Layout showInterstitial={isHomePage}>
        <MetaTags
          type="river"
          content={{ slug: league.slug, uri: league.resource_uri, sport_name: league.sport_name }}
        />
        <LeagueRiverComponent
          league={league}
          riverData={newRiverData}
          countryCode={countryCode}
          currentCardId={contentCard.id}
          showTag={showTag}
        >
          <ContentCardComponent contentCard={contentCard} isLarge showTag={showTag} />
        </LeagueRiverComponent>
        <MobileBannerAdComponent />
      </Layout>
    );
  }
}

const leagueShape = PropTypes.shape({
  slug: PropTypes.string,
  short_name: PropTypes.string,
  medium_name: PropTypes.string,
  sections: PropTypes.arrayOf(PropTypes.object),
  sport_name: PropTypes.string,
});

LeagueRiver.propTypes = {
  leagueSlug: PropTypes.string.isRequired,
  riverData: PropTypes.shape({
    relatedContent: PropTypes.array,
    before: PropTypes.string,
  }).isRequired,
  experiments: PropTypes.array,
  league: leagueShape.isRequired,
  leagues: PropTypes.arrayOf(leagueShape).isRequired,
  countryCode: PropTypes.string,
  getTicker: PropTypes.func.isRequired,
  isServer: PropTypes.bool,
  riverDataFailure: PropTypes.bool,
  router: PropTypes.shape({
    pathname: PropTypes.string,
    query: PropTypes.shape({
      group: PropTypes.string,
      leagueSlug: PropTypes.string,
    }),
    asPath: PropTypes.string,
  }).isRequired,
};

LeagueRiver.defaultProps = {
  experiments: [],
  countryCode: '',
  isServer: false,
  riverDataFailure: false,
};

LeagueRiver.displayName = 'LeagueRiverPage';

const mapStateToProps = state => ({
  riverData: state.pageData.riverData,
});

const mapDispatchToProps = dispatch => ({
  getTicker: bindActionCreators(getTicker, dispatch),
});

export default withState({ createStore: initStore, mapStateToProps, mapDispatchToProps })(
  withRouter(LeagueRiver)
);
