exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".MobileBannerAd__mobileBannerAd--31oDQ {\n  display: none;\n}\n\n@media (max-width: 992px) {\n  .MobileBannerAd__mobileBannerAd--31oDQ {\n    background: #000;\n    display: block;\n    margin: 0 auto;\n    position: fixed;\n    width: 100%;\n    bottom: 0;\n    z-index: 2;\n  }\n}", ""]);

// exports
exports.locals = {
	"mobileBannerAd": "MobileBannerAd__mobileBannerAd--31oDQ"
}; 
var style = module.exports.toString();module.exports = exports.locals || { stylesheet: "" };if (!module.exports.stylesheet) { module.exports.stylesheet = style; };